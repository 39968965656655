// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bailarin-tsx": () => import("./../../../src/pages/bailarin.tsx" /* webpackChunkName: "component---src-pages-bailarin-tsx" */),
  "component---src-pages-bio-tsx": () => import("./../../../src/pages/bio.tsx" /* webpackChunkName: "component---src-pages-bio-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-profesor-tsx": () => import("./../../../src/pages/profesor.tsx" /* webpackChunkName: "component---src-pages-profesor-tsx" */),
  "component---src-pages-proyectos-tsx": () => import("./../../../src/pages/proyectos.tsx" /* webpackChunkName: "component---src-pages-proyectos-tsx" */),
  "component---src-templates-actor-template-tsx": () => import("./../../../src/templates/actor-template.tsx" /* webpackChunkName: "component---src-templates-actor-template-tsx" */),
  "component---src-templates-director-template-tsx": () => import("./../../../src/templates/director-template.tsx" /* webpackChunkName: "component---src-templates-director-template-tsx" */),
  "component---src-templates-proyecto-template-tsx": () => import("./../../../src/templates/proyecto-template.tsx" /* webpackChunkName: "component---src-templates-proyecto-template-tsx" */)
}

